.checkbox-circle-container .checkbox-circle-hidden-child {
	visibility: hidden;
}

.checkbox-circle-container:hover .checkbox-circle-hidden-child {
	visibility: visible;
}

.checkbox-circle {
	position: relative;
	margin: 4px 10px 0 0;
}

.checkbox-circle label {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: default;
	height: 21px;
	left: 0;
	position: absolute;
	top: 0;
	width: 21px;
}

.checkbox-circle label:hover {
	background-color: #ccc;
}

.checkbox-circle label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: '';
	height: 4.5px;
	left: 5.25px;
	opacity: 0;
	position: absolute;
	top: 6px;
	transform: rotate(-45deg);
	width: 9px;
}

.checkbox-circle input[type='checkbox'] {
	visibility: hidden;
}

.checkbox-circle input[type='checkbox']:checked + label {
	background-color: #66bb6a;
	border-color: #66bb6a;
	visibility: visible;
}

.checkbox-circle input[type='checkbox']:checked + label:after {
	opacity: 1;
}

